<template>
  <div class="welcome">
    <div class="container1">
      <div class="text">
        <h1>{{ $t("refugeesHistory.h1") }}</h1>
        <p><span>{{ $t("refugeesHistory.span2") }}</span>{{ $t("refugeesHistory.p3") }}</p>
      </div>
    </div>
    <div class="container2">
      <img class="img1" src="@/assets/images/img9.jpg" alt="img" />
      <img class="img2" src="@/assets/images/img7.jpg" alt="img" />
    </div>
    <div class="container3">
      <div class="text">
        <p>{{ $t("refugeesHistory.p1") }}</p>
      </div>
    </div>
    <svg class="svg2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
      <path class="elementor-shape-fill" opacity="0.33" d="M473,67.3c-203.9,88.3-263.1-34-320.3,0C66,119.1,0,59.7,0,59.7V0h1000v59.7 c0,0-62.1,26.1-94.9,29.3c-32.8,3.3-62.8-12.3-75.8-22.1C806,49.6,745.3,8.7,694.9,4.7S492.4,59,473,67.3z"></path>
      <path class="elementor-shape-fill" opacity="0.66" d="M734,67.3c-45.5,0-77.2-23.2-129.1-39.1c-28.6-8.7-150.3-10.1-254,39.1 s-91.7-34.4-149.2,0C115.7,118.3,0,39.8,0,39.8V0h1000v36.5c0,0-28.2-18.5-92.1-18.5C810.2,18.1,775.7,67.3,734,67.3z"></path>
      <path class="elementor-shape-fill" d="M766.1,28.9c-200-57.5-266,65.5-395.1,19.5C242,1.8,242,5.4,184.8,20.6C128,35.8,132.3,44.9,89.9,52.5C28.6,63.7,0,0,0,0 h1000c0,0-9.9,40.9-83.6,48.1S829.6,47,766.1,28.9z"></path>
    </svg>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.welcome {
  position: relative;
  min-height: 100vh;
  width: 100%;
  color: #000;
  background: #f4f4f4;

  display: flex;
  flex-direction: column;
  margin: 40px 0 0 0;
}
.container1 {
  background: #f4f4f4;
  width: 100%;
  display: flex;
  padding: 0 100px;
}
.container2 {
  display: flex;
  background: #f4f4f4;
}
.container2 .img1 {
  width: 50%;
  padding: 20px 20px 20px 120px;
}
.container2 .img2 {
  width: 50%;
  padding: 20px 120px 20px 20px;
}
.container3 {
  width: 100%;
  padding: 0 100px;
}

.text {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}
.text h1 {
  text-align: center;
  font-weight: bold;
  font-size: 35px;
  font-family: "ubuntu", sans-serif;
  padding: 50px;
  color: #ff9b13;
}
.text span {
  font-weight: bold;
}
.text p {
  text-align: justify;
}
.svg2 {
  height: 60px;
  transform: translateY(60px);
  fill: #f4f4f4;
}
@media (max-width: 1050px) {
  .container1,
  .container3 {
    padding: 10px;
    flex-direction: column;
  }
  .container1 h1 {
    font-size: 25px;
    padding: 0;
    padding-bottom: 40px;
    text-align: center;
    width: 100%;
  }
  .container2 {
    flex-direction: column;
  }
  .container2 .img1 {
    display: none;
  }
  .container2 .img2 {
    padding: 0;
    width: 100%;
  }
  .svg2 {
    height: 20px;
    transform: translateY(19px);
    fill: #f4f4f4;
  }
}
</style>