<template>
  <div class="home">  
    <Header />
    <Policy />
    <Banner />
    <About />
    <RefugeesHistory />
    <LastNotices />
    <Contact />
    <Feedback />
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header";
import Policy from "@/components/Policy"
import Banner from "@/components/Banner";
import About from "@/components/About"
import RefugeesHistory from "@/components/RefugeesHistory";
import LastNotices from "../components/LastNotices.vue";
import Contact from "@/components/Contact";
import Feedback from '@/components/Feedback';
import Footer from "@/components/Footer";

export default {
  components: {
    Header,
    Policy,
    Banner,
    About,
    RefugeesHistory,
    LastNotices,
    Contact,
    Feedback,
    Footer,
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
</style>