<template>
  <div class="about">
    <svg
      class="svg"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1000 100"
      preserveAspectRatio="none"
    >
      <path
        class="elementor-shape-fill"
        opacity="0.33"
        d="M473,67.3c-203.9,88.3-263.1-34-320.3,0C66,119.1,0,59.7,0,59.7V0h1000v59.7 c0,0-62.1,26.1-94.9,29.3c-32.8,3.3-62.8-12.3-75.8-22.1C806,49.6,745.3,8.7,694.9,4.7S492.4,59,473,67.3z"
      ></path>
      <path
        class="elementor-shape-fill"
        opacity="0.66"
        d="M734,67.3c-45.5,0-77.2-23.2-129.1-39.1c-28.6-8.7-150.3-10.1-254,39.1 s-91.7-34.4-149.2,0C115.7,118.3,0,39.8,0,39.8V0h1000v36.5c0,0-28.2-18.5-92.1-18.5C810.2,18.1,775.7,67.3,734,67.3z"
      ></path>
      <path
        class="elementor-shape-fill"
        d="M766.1,28.9c-200-57.5-266,65.5-395.1,19.5C242,1.8,242,5.4,184.8,20.6C128,35.8,132.3,44.9,89.9,52.5C28.6,63.7,0,0,0,0 h1000c0,0-9.9,40.9-83.6,48.1S829.6,47,766.1,28.9z"
      ></path>
    </svg>
    <div class="title">
      <h1>{{ $t("about.h1") }}</h1>
    </div>
    <div class="text">
      <p>{{ $t("about.p") }}</p>
    </div>
    <div class="btn">
      <router-link :to="{ name: 'about-PT' }">{{
        $t("about.link")
      }} >></router-link>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.svg {
  position: absolute;
  top: -59px;
  left: 0;
  width: 100%;
  height: 60px;
  z-index: 100;
  transform: rotateX(-180deg);
  fill: #fff;
}
.about {
  position: relative;
  width: 100%;
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 120px;
}
.title h1 {
  font-size: 35px;
  font-family: "ubuntu", sans-serif;
  font-weight: bold;
  color: #ff9b13;
  padding-bottom: 60px;
}
.text p {
  font-size: 18px;
  text-align: center;
  padding-bottom: 50px;
  color: #212529;
}
.btn a {
  background: #ff9b13;
  color: #fff;
  padding: 10px 20px;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
}
.btn a:hover {
  background: #dd8100;
}
@media (max-width: 1050px) {
  .about {
    padding: 50px 30px 30px 30px;
  }
  .title h1 {
    text-align: center;
    font-size: 25px;
  }
  .text {
    margin: 0;
    padding: 0;
  }
  .text p {
    text-align: justify;
    font-size: initial;
  }
  .btn a{
    padding: 8px 10px;
    font-size: 14px;
  }
  .svg {
    top: -19px;
    height: 20px;
  }
}
</style>