<template>
  <div class="container-carousel">
    <div class="logo">
      <img src="@/assets/images/LOGO.png" alt="logo" />
    </div>
    <fa-icon id="arrow" :icon="['fas', 'angle-double-down']" />
    <button class="btn">
      <router-link :to="{ name: 'about-PT' }">{{$t('banner.btn')}}</router-link>
    </button>
    
    <!-- <b-carousel
      id="carousel-1"
      :interval="4000"
      fade
      controls
      background="#ababab"
      img-width="1024"
      img-height="480"
      style="text-shadow: 1px 1px 2px #333"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
    >
      <b-carousel-slide>
        <template #img>
          <img
            id="imagem"
            class="d-block img-fluid w-100"
            width="1024"
            height="480"
            src="@/assets/images/img1.jpg"
            alt="image slot"
          />
        </template>
      </b-carousel-slide>
      <b-carousel-slide>
        <template #img>
          <img
            id="imagem"
            class="d-block img-fluid w-100"
            width="1024"
            height="480"
            src="@/assets/images/img2.jpg"
            alt="image slot"
          />
        </template>
      </b-carousel-slide>
      <b-carousel-slide>
        <template #img>
          <img
            id="imagem"
            class="d-block img-fluid w-100"
            width="1024"
            height="480"
            src="@/assets/images/img3.jpg"
            alt="image slot"
          />
        </template>
      </b-carousel-slide>
      <b-carousel-slide>
        <template #img>
          <img
            id="imagem"
            class="d-block img-fluid w-100"
            width="1024"
            height="480"
            src="@/assets/images/img4.jpg"
            alt="image slot"
          />
        </template>
      </b-carousel-slide>
    </b-carousel> -->
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
  },
};
</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.container-carousel {
  height: 550px;
  background:  url("../assets/images/rio.jpg");
  background-size: cover;
  background-position: center;
}
.img{
  position: relative;
  width: 100%;
  height: 550px;
}
/* #imagem {
  height: 550px;
  width: 100%;
} */
.logo {
  position: absolute;
  top: 200px;
  left: 100px;
  z-index: 100;
  transition: 0.4s;
}
#arrow {
  font-size: 40px;
  color: #fff;
  position: absolute;
  top: 500px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  animation: effect 2s ease-in-out infinite;
}
@keyframes effect {
  0% {
    transform: translateY(0px);
    opacity: 0;
  }
  50% {
    transform: translateY(15px);
    opacity: 1;
  }
  100% {
    transform: translateY(30px);
    opacity: 0;
  }
}
.btn {
  position: absolute;
  top: 460px;
  left: 120px;
  padding: 10px 20px;
  border: none;
  border-radius: 0;
  background: #ff9b13;
  z-index: 100;
  transition: 0.4s;
}
.btn a {
  color: #fff;
  text-decoration: none;
  transition: 0.2s;
}
.btn:hover {
  background: #212529;
}
@media (max-width: 975px) {
.logo{
  left: 40px;
}
.btn{
  left: 60px;
}
}
@media(max-width: 640px){
  .container-carousel {
    height: 50vh;
  }
  .img{
    height: 50vh;
  }
  /* #imagem {
    height: 40vh;
  } */
  .logo {
    top: 20vh;
    left: 15px;
  }
  .logo img {
    width: 300px;
  }
  .btn {
    display: none;
  }
  #arrow {
    display: none;
  }
}
</style>