<template>
	<div class="notices">
		<div class="title">
			<h1>{{ this.$t("lastNotices.h1") }}</h1>
		</div>
		<LastNoticesCardTemplate :card="prefeitura" />
	</div>
</template>

<script>
import LastNoticesCardTemplate from "./templates/LastNoticesCardTemplate.vue";
export default {
	components: {
		LastNoticesCardTemplate
	},
	data() {
		return {
			prefeitura: {
				title: this.$t("lastNotices.prefeituraTitle"),
				text: this.$t("lastNotices.prefeituraTexto"),
				link: "https://www.acnur.org/portugues/2022/02/25/prefeitura-da-cidade-do-rio-de-janeiro-institui-programa-de-auxilio-ao-refugiado/",
				linkName: this.$t("lastNotices.linkName"),
			},
		}
	}
}

</script>

<style scoped>
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

.notices {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	padding: 80px 0 40px 0;
}

.notices .title {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 0 40px 0;
}

.notices .title h1 {
	text-align: center;
	font-weight: bold;
	font-size: 35px;
	font-family: "ubuntu", sans-serif;
	padding: 20px;
	color: #ff9b13;
}

@media screen and (max-width: 1000px) {
	.notices .title h1 {
		font-size: 25px;
		padding: 0;
		padding-bottom: 40px;
		text-align: center;
		width: 100%;
	}
}
</style>